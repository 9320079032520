body,
html {
  margin: 0;
  padding: 0;
  font-family: "Inter, sans-serif";
}

@font-face {
  font-family: 'New York';
  src: url('./fonts/New York Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'Times New Roman';
  src: url('./fonts/Times New Roman.ttf') format("truetype");
}

iframe#webpack-dev-server-client-overlay{display:none!important}
