.thumbnails-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.thumbnails-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.thumbnails-container::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}
