.live-recording-icon {
  border-radius: 50%; /* Make it a circle */
  background-color: red; /* Circle color */
  position: relative; /* For positioning */
  animation: fade 1s infinite; /* Animation for fading */
}

.fade-in {
  opacity: 1; /* Fully visible */
}

.fade-out {
  opacity: 0; /* Fully transparent */
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
