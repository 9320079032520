@font-face {
  font-family: "Brandon";
  src: url("./assets/Brandon_med.otf");
}
.disruption__content-container {
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  margin: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}
.disruption__service-affected_container {
  width: 100%;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.disruption__top-text-container {
  width: 85%;
  background-color: #ffd140;
  font-family: "Brandon";
  font-size: 3vmin;
  text-align: center;
}
.disruption__line-text-container {
  width: 85%;
  font-family: "Brandon";
  font-size: 3vmin;
  text-align: center;
}
.disruption__line-image-container {
  width: 85%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.disruption__line-image-container > img {
  width: 38%;
  height: auto;
}
.disruption__time-container {
  width: 85%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.disruption__time-info-text {
  width: 70%;
  font-size: 3vmin;
}
.disruption__time-text {
  width: 25%;
  font-size: 4vmin;
}
.affected__service-affected_container {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
}
.affected__top-container {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  flex-wrap: nowrap;
}
.affected__top-text-container {
  background-color: #ffd140;
  font-family: "Brandon";
  font-size: 3vmin;
  text-align: center;
}
.affected__line-text-container {
  font-family: "Brandon";
  font-size: 3vmin;
  text-align: center;
}
.affected__station-list {
  padding: 3% 0 3% 0;
  width: 90%;
  height: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.affected__station-list_item {
  flex-basis: 20%;
  text-align: center;
  height: 30%;
}
.affected__station-list_item > img {
  height: 100%;
}
.no-service-img {
  height: calc(9vmin + 8px);
}
.bottom-text-container {
  font-family: "Brandon";
  font-size: 3.5vmin;
}
