/* Add gap between events in day grid */
.fc-daygrid-event {
  margin-bottom: 1px; /* Adjust the gap size here */
  cursor: pointer;
}
.fc-daygrid-day-top {
  position: sticky;
  top: 0;
  background-color: #ffffff; /* Ensure the header background is solid */
  z-index: 10; /* Ensure it stays above event content */
  border-bottom: 1px solid #ddd; /* Add a border to distinguish the sticky header */
}

/* Ensure event content is below the sticky header */
.fc-daygrid-day {
  position: relative; /* Ensure event content is positioned relative to its normal position */
  z-index: 1; /* Lower than the sticky header */
  border-right: 2px solid #dddddd80 !important;
}

.fc-col-header-cell {
  border-bottom: 2px solid #333;
}

.calendar-container {
  position: relative;
}

.tooltip {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 8px;
  border-radius: 4px;
  pointer-events: none;
  z-index: 1000;
  white-space: nowrap;
}
